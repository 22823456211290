import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { getBuybackItems, updateBuybackPaymentStatus, getBuybackReviewsByItemId } from "../api/AdminApi";

type PaymentMethod = 'CASH' | 'CREDIT_CARD' | 'BANK_TRANSFER' | 'OTHER';

interface ProductPaymentItem {
  id: number;
  orderId: string;
  productName: string;
  customerName: string;
  buybackPrice: string;
  finalBuybackPrice: string;
  paymentStatus: 'PENDING' | 'INITIATED' | 'PAID' | 'CANCELLED';
  paymentMethod: PaymentMethod;
  poid: string | null;
  poNumber: string | null;
  customerComments: string;
  reviewComment: string;
  itemRepairComments: string;
  customerAddress: string;
  storeDrop: boolean;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string;
  inventorySku: string;
  inventoryType: "USED_PRODUCT" | "RENTAL_PRODUCT";
  buybackStatus: string;
}

interface CommentPopupProps {
  customerComment: string;
  reviewComment: string;
  itemRepairComments: string;
  customerAddress: string;
  storeDrop: boolean;
  paymentMethod: string;
  customerPhone: string;
  reviewDate: string;
  alternatePhoneNo: string;
  onClose: () => void;
}

const CommentPopup: React.FC<CommentPopupProps> = ({
  customerComment,
  reviewComment,
  itemRepairComments,
  customerAddress,
  storeDrop,
  paymentMethod,
  customerPhone,
  reviewDate,
  alternatePhoneNo,
  onClose
}) => {
  // Format reviewDate to 'YYYY-MM-DD'
  const formattedReviewDate = reviewDate
    ? new Date(reviewDate).toISOString().split("T")[0]
    : "Not specified";

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-black-700 bg-white border-black">✕</button>
        </div>
        <div className="space-y-4">
          <div>
            <h4 className="font-bold text-gray-700">Customer Address:</h4>
            <p className="mt-1 text-gray-600">{customerAddress || 'No address provided'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Store Drop:</h4>
            <p className="mt-1 text-gray-600">{storeDrop ? 'Yes' : 'No'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Payment Method:</h4>
            <p className="mt-1 text-gray-600">{paymentMethod || 'Not specified'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Phone Numbers:</h4>
            <p className="mt-1 text-gray-600">
              Primary: {customerPhone || 'Not provided'}<br />
              Alternate: {alternatePhoneNo || 'Not provided'}
            </p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Desired Pickup Date:</h4>
            <p className="mt-1 text-gray-600">{formattedReviewDate}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Customer Comment:</h4>
            <p className="mt-1 text-gray-600">{customerComment || 'No customer comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Review Comment:</h4>
            <p className="mt-1 text-gray-600">{reviewComment || 'No review comment'}</p>
          </div>
          <div>
            <h4 className="font-bold text-gray-700">Repair Comments:</h4>
            <p className="mt-1 text-gray-600">{itemRepairComments || 'No repair comments'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export function ProductPayment() {
  const [data, setData] = useState<ProductPaymentItem[]>([]);
  const [finalPrices, setFinalPrices] = useState<string[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [selectedInventoryType, setSelectedInventoryType] = useState<{ [key: number]: "USED_PRODUCT" | "RENTAL_PRODUCT"; }>({});
  const [inventorySkus, setInventorySkus] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [selectedComment, setSelectedComment] = useState<{
    customerComment: string;
    reviewComment: string;
    itemRepairComments: string;
    customerAddress: string;
    storeDrop: boolean;
    paymentMethod: string;
    customerPhone: string;
    reviewDate: string;
    alternatePhoneNo: string;
  } | null>(null);

  const [paymentPageCount, setPaymentPageCount] = useState(1);
  const [paymentPageNumber, setPaymentPageNumber] = useState(1);
  const [itemsPerPage] = useState(20);

  useEffect(() => {
    const fetchBuybackItems = async () => {
      try {
        const additionalFiltersForItems = {
          buyback_status: ["APPROVED"]
        };

        const itemsResponse = await getBuybackItems(itemsPerPage, paymentPageNumber, additionalFiltersForItems);
        const itemIds = itemsResponse.data.map((item) => item.id);

        const reviews = await Promise.all(
          itemIds.map(async (itemId) => {
            const review = await getBuybackReviewsByItemId(itemId);
            const reviewData = Array.isArray(review.data) ? review.data[0] : review.data;
            return {
              itemId,
              reviewData: reviewData || {},
            };
          })
        );
        
        const reviewsMap = new Map(
          reviews.map(({ itemId, reviewData }) => [
            itemId,
            {
              customerComments: reviewData.customer_comments || "",
              reviewComment: reviewData.review_comments || "",
              inspectionStatus: reviewData.inspection_status,
              storeDrop: reviewData.store_drop || false,
              reviewDate: reviewData.cs_review_date || "",
            },
          ])
        );

        const paymentData = itemsResponse.data
          .filter((item) => {
            const review = reviewsMap.get(item.id);
            return review?.inspectionStatus !== "REJECTED";
          })
          .map((item) => {
            const review = reviewsMap.get(item.id);
            return {
              id: item.id,
              orderId: `${item.order_id}`,
              productName: item.product_name,
              customerName: `${item.customer_firstname} ${item.customer_lastname}`,
              buybackPrice: item.buyback_price.toString(),
              finalBuybackPrice: item.final_buyback_price ? item.final_buyback_price.toString() : "",
              paymentStatus: item.payment_status,
              paymentMethod: item.payment_method as PaymentMethod,
              poid: item.po_id,
              poNumber: item.po_no,
              customerComments: review?.customerComments || "",
              reviewComment: review?.reviewComment || "",
              inspectionStatus : review?.inspectionStatus,
              itemRepairComments: item.repair_comments || "",
              customerAddress: item.customer_address || "",
              storeDrop: Boolean(review?.storeDrop) || false,
              customerPhone: item.customer_phone || "",
              reviewDate: review?.reviewDate || "",
              alternatePhoneNo: item.alternate_phone_no || "",
              inventoryType: item.inventory_type,
              inventorySku: item.inventory_sku,
              buybackStatus: item.buyback_status
            };
          });
        
        setPaymentPageCount(Math.ceil(itemsResponse.pagination.totalItemsCount/itemsPerPage));
        setData(paymentData);
        setFinalPrices(paymentData.map(item => item.finalBuybackPrice));
        setPaymentMethods(paymentData.map(item => item.paymentMethod));
        setSelectedInventoryType(
          paymentData.reduce((acc, item, index) => ({
            ...acc,
            [index]: item.inventoryType
          }), {})
        );
        setInventorySkus(paymentData.map(item => item.inventorySku));
        setErrors(new Array(paymentData.length).fill(""));
      } catch (error) {
        console.error("Error fetching buyback items:", error);
      }
    };

    fetchBuybackItems();
  }, [paymentPageNumber, itemsPerPage]);

  const handlePaymentPageClick = (event: { selected: number }) => {
    setPaymentPageNumber(event.selected+1);
  };

  const handleFinalPriceChange = (index: number, value: string) => {
    const newPrices = [...finalPrices];
    newPrices[index] = value;
    setFinalPrices(newPrices);
  };

  const handlePaymentMethodChange = (index: number, value: string) => {
    const newMethods = [...paymentMethods];
    newMethods[index] = value as PaymentMethod; // Cast to PaymentMethod type
    setPaymentMethods(newMethods);
  };

  const handleInventoryTypeChange = (
    index: number,
    value: "USED_PRODUCT" | "RENTAL_PRODUCT"
  ) => {
    setSelectedInventoryType(prevState => ({
      ...prevState,
      [index]: value,
    }));
    setErrors(prev => {
      const newErrors = [...prev];
      newErrors[index] = "";
      return newErrors;
    });
  };

  const handleInvSkuChange = (index: number, value: string) => {
    const newSkus = [...inventorySkus];
    newSkus[index] = value;
    setInventorySkus(newSkus);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const validateFields = (index: number): boolean => {
    let errorMessage = "";
    if (!paymentMethods[index]) {
      errorMessage = "Please select a payment method.";
    } else if (!finalPrices[index] || parseFloat(finalPrices[index]) <= 0) {
      errorMessage = "Final buyback price must be greater than zero.";
    }

    console.log(validateFields);

    const newErrors = [...errors];
    newErrors[index] = errorMessage;
    setErrors(newErrors);

    return !errorMessage;
  };

  const handleCompletePayment = async (id: number, index: number) => {
    try {
      const finalPrice = finalPrices[index];
      const selectedPaymentMethod = paymentMethods[index];
      const inventorySku = inventorySkus[index];
      const inventoryType = selectedInventoryType[index];
      const buybackStatus = data[index].buybackStatus;

      if (!finalPrice) {
        setErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = "Please enter a final price";
          return newErrors;
        });
        return;
      }

      if (!selectedPaymentMethod) {
        setErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = "Please select a payment method";
          return newErrors;
        });
        return;
      }

      await updateBuybackPaymentStatus(
        id,
        finalPrice,
        'PAID',
        selectedPaymentMethod,
        inventorySku,
        inventoryType,
        buybackStatus
      );

      // Update the local state
      setData((prevData) =>
        prevData.map((item) =>
          item.id === id
            ? {
              ...item,
              paymentStatus: 'PAID',
              finalBuybackPrice: finalPrice,
              inventorySku: inventorySku,
              inventoryType: inventoryType,
              paymentMethod: selectedPaymentMethod
            }
            : item
        )
      );

      // Clear any errors
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index] = "";
        return newErrors;
      });
    } catch (error) {
      console.error("Error completing payment:", error);
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index] = "Error completing payment";
        return newErrors;
      });
    }
  };

  const handleInitiatePayment = async (id: number, index: number) => {
    try {
      const finalPrice = finalPrices[index];
      const selectedPaymentMethod = paymentMethods[index];
      const inventorySku = inventorySkus[index];
      const inventoryType = selectedInventoryType[index];
      const buybackStatus = data[index].buybackStatus;

      // Validation checks
      if (!finalPrice) {
        setErrors(prev => {
          const newErrors = [...prev];
          newErrors[index] = "Please enter a final price";
          return newErrors;
        });
        return;
      }

      if (!selectedPaymentMethod) {
        setErrors(prev => {
          const newErrors = [...prev];
          newErrors[index] = "Please select a payment method";
          return newErrors;
        });
        return;
      }

      if (!inventorySku) {
        setErrors(prev => {
          const newErrors = [...prev];
          newErrors[index] = "Please enter an inventory SKU";
          return newErrors;
        });
        return;
      }

      if (!inventoryType) {
        setErrors(prev => {
          const newErrors = [...prev];
          newErrors[index] = "Please select an inventory type";
          return newErrors;
        });
        return;
      }

      // Call API to update payment status
      await updateBuybackPaymentStatus(
        id,
        finalPrice,
        'INITIATED',
        selectedPaymentMethod,
        inventorySku,
        inventoryType,
        buybackStatus
      );

      // Update local state after successful API call
      setData(prevData =>
        prevData.map(item =>
          item.id === id
            ? {
              ...item,
              paymentStatus: 'INITIATED',
              finalBuybackPrice: finalPrice,
              inventorySku: inventorySku,
              inventoryType: inventoryType,
              paymentMethod: selectedPaymentMethod
            }
            : item
        )
      );

      // Clear error for this index
      setErrors(prev => {
        const newErrors = [...prev];
        newErrors[index] = "";
        return newErrors;
      });

      // Show success message (you'll need to implement toast or another notification system)
      alert("Payment initiated successfully");

    } catch (error) {
      console.error("Error initiating payment:", error);
      setErrors(prev => {
        const newErrors = [...prev];
        newErrors[index] = "Error initiating payment";
        return newErrors;
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "."
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Product Payment
      </h2>
      <table className="w-full">
        <thead>
          <tr className="bg-[#f78a8a] text-white">
            <th className="py-2 px-4 text-left">ORDER ID</th>
            <th className="py-2 px-4 text-left">PRODUCT NAME</th>
            <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
            <th className="py-2 px-4 text-left">PAYMENT</th>
            <th className="py-2 px-4 text-left">PURCHASE ORDER</th>
            <th className="py-2 px-4 text-left">PAYMENT STATUS</th>
            <th className="py-2 px-4 text-left">PAYMENT METHOD</th>
            <th className="py-2 px-4 text-left">INVENTORY SKU</th>
            <th className="py-2 px-4 text-left">INVENTORY TYPE</th>
            <th className="py-2 px-4 text-left">DETAILS</th>
            <th className="py-2 px-4 text-left">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id} className="border-b">
              <td className="py-2 px-4">{item.orderId}</td>
              <td className="py-2 px-4">{item.productName}</td>
              <td className="py-2 px-4">{item.customerName}</td>

              <td className="py-2 px-4">
                <input
                  type="text"
                  value={`${finalPrices[index]}`}
                  onChange={(e) =>
                    handleFinalPriceChange(
                      index,
                      e.target.value.replace(/^\$/, "")
                    )
                  }
                  onKeyDown={handleKeyDown}
                  className="w-full p-2 border rounded bg-white"
                  required
                  placeholder="Enter amount"
                />
              </td>
              <td className="py-2 px-4">{item.poNumber}</td>
              <td className="py-2 px-4">{item.paymentStatus}</td>
              <td className="py-2 px-4">
                <select
                  value={paymentMethods[index] || ""}
                  onChange={(e) =>
                    handlePaymentMethodChange(index, e.target.value)
                  }
                  className="w-full p-2 border rounded bg-white"
                >
                  <option value=""></option>
                  <option value="CASH">Cash</option>
                  <option value="BANK_TRANSFER">Bank Transfer</option>
                </select>
              </td>
              {<td className="py-2 px-4">
                <input
                  type="text"
                  placeholder="Enter SKU"
                  className="w-full p-2 border rounded bg-white"
                  value={item.inventorySku}
                  onChange={(e) => handleInvSkuChange(index, e.target.value)}
                  readOnly={item.paymentStatus === "PAID"}
                />
              </td>}

              {<td className="py-2 px-4">
                <select
                  className="w-full p-2 border rounded bg-white"
                  value={
                    selectedInventoryType[index] || item.inventoryType || ""
                  }
                  onChange={(e) =>
                    handleInventoryTypeChange(
                      index,
                      e.target.value as "USED_PRODUCT" | "RENTAL_PRODUCT"
                    )
                  }
                >
                  <option value="">Select</option>
                  <option value="USED_PRODUCT">Used Product</option>
                  <option value="RENTAL_PRODUCT">Rental Product</option>
                </select>
                {!selectedInventoryType[index] && !item.inventoryType && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors[index]}
                  </p>
                )}
              </td>}

              <td className="py-2 px-4">
                <button
                  className="text-blue-600 hover:text-blue-800 underline bg-white"
                  onClick={() => setSelectedComment({
                    customerComment: item.customerComments,
                    reviewComment: item.reviewComment,
                    itemRepairComments: item.itemRepairComments,
                    customerAddress: item.customerAddress,
                    storeDrop: item.storeDrop,
                    paymentMethod: item.paymentMethod,
                    customerPhone: item.customerPhone,
                    reviewDate: item.reviewDate,
                    alternatePhoneNo: item.alternatePhoneNo
                  })}
                >
                  View
                </button>
              </td>
              <td className="py-2 px-4">
                {item.paymentStatus === 'PENDING' && (
                  <button
                    onClick={() => handleInitiatePayment(item.id, index)}
                    className="px-4 py-2 rounded-full bg-green-500 text-white hover:bg-green-600"
                  >
                    INITIATE PAYMENT
                  </button>
                )}

                {item.paymentStatus === 'INITIATED' && (
                  <button
                    onClick={() => handleCompletePayment(item.id, index)}
                    className="px-4 py-2 rounded-full bg-green-500 text-white hover:bg-green-600"
                  >
                    COMPLETE PAYMENT
                  </button>
                )}

                {item.paymentStatus === 'PAID' && (
                  <button
                    disabled
                    className="px-4 py-2 rounded-full bg-gray-300 text-gray-500 cursor-not-allowed"
                  >
                    PAID
                  </button>
                )}

                {errors[index] && (
                  <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          className="flex items-center justify-center gap-2 mt-4"
          pageLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          previousLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          nextLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          activeLinkClassName="!bg-primary !text-white hover:!bg-primary"
          disabledLinkClassName="opacity-50 cursor-not-allowed"
          breakLinkClassName="px-3 py-2"
          pageCount={paymentPageCount}
          onPageChange={handlePaymentPageClick}
          renderOnZeroPageCount={null}
        />
      {selectedComment && (
        <CommentPopup
          customerComment={selectedComment.customerComment}
          reviewComment={selectedComment.reviewComment}
          itemRepairComments={selectedComment.itemRepairComments}
          customerAddress={selectedComment.customerAddress}
          storeDrop={selectedComment.storeDrop}
          paymentMethod={selectedComment.paymentMethod}
          customerPhone={selectedComment.customerPhone}
          reviewDate={selectedComment.reviewDate}
          alternatePhoneNo={selectedComment.alternatePhoneNo}
          onClose={() => setSelectedComment(null)}
        />
      )}
    </div>
  );
}
