import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { StoreProvider } from "./context/StoreContext";
import { BuybackProvider } from "./context/BuybackContext";
import HomePage from "./market/pages/HomePage";
import ProductDetailsPage from "./market/pages/ProductDetailsPage";
import Header from "./market/components/Header/Header";
import Navbar from "./market/components/Navbar/Navbar";
import Footer from "./market/components/Footer/Footer";
import ProductListingPage from "./market/pages/ProductListingPage";
import BuybackAdminScreen from "./admin/pages/BuybackScreen";
import AuthComponent from "./auth/AuthComponent";
import BuybackFormPage from "./market/pages/BuybackFormPage";
import BuybackFormAfterSubmit from "./market/pages/BuybackFormAfterSubmit";
import UnauthorizedPage from './auth/components/UnauthorizedPage';
import ProtectedRoute from "./auth/components/ProtectedRoute";

const MainLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <Header />
    <Navbar />
    {children}
    <Footer />
  </>
);

const BuybackScreen = ({ children }: { children: React.ReactNode }) => (
  <div className="">{children}</div>
);

const BuybackForm = ({ children }: { children: React.ReactNode }) => (
  <div className="">{children}</div>
);

export function App() {
  return (
    <StoreProvider>
      <BuybackProvider>
        <div className="relative bg-white gap-12 min-h-screen text-xs text-gray-800 font-sora">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout><HomePage /></MainLayout>} />
              <Route path="/categories/:categoryName" element={<MainLayout><ProductListingPage /></MainLayout>} />
              <Route
                path="/product/:urlKey"
                element={
                  <MainLayout>
                    <ProtectedRoute>
                      <ProductDetailsPage />
                    </ProtectedRoute>
                  </MainLayout>
                }
              />
              <Route
                path="/admin/buyback"
                element={
                  <BuybackScreen>
                    <ProtectedRoute requiredRoles={['sehaa-customer-service']}>
                      <BuybackAdminScreen />
                    </ProtectedRoute>
                  </BuybackScreen>
                }
              />
              <Route
                path="/market/buyback/form/:itemId"
                element={
                  <BuybackForm>
                    <BuybackFormPage />
                  </BuybackForm>
                }
              />
               <Route
                path="/market/buyback/:itemId"
                element={
                  <BuybackForm>
                    <BuybackFormAfterSubmit/>
                  </BuybackForm>
                }
              />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
              <Route 
                path="/login" 
                element={<AuthComponent><Navigate to="/" replace /></AuthComponent>} 
              />
            </Routes>
          </BrowserRouter>
        </div>
      </BuybackProvider>
    </StoreProvider>
  );
}

export default App;
