import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  upcomingBuybackData,
  pastBuybackData,
  searchBuybackByParam,
  updateBuybackItem,
  deleteBuybackItem,
} from "../api/AdminApi";
import { BuybackResponse } from "../types/types";

interface UpcomingBuybackItem {
  id: number;
  orderId: string;
  productName: string;
  customerFirstname: string;
  customerLastname: string;
  phoneNumber: string;
  emailId: string;
  dateTime: Date;
  buybackStatus: string;
}

export function UpcomingBuyback() {
  const [data, setData] = useState<UpcomingBuybackItem[]>([]);
  const [upcomingItems, setUpcomingItems] = useState<UpcomingBuybackItem[]>([]);
  const [buybackList, setBuybackList] = useState<UpcomingBuybackItem[]>([]);
  const [upcomingPageCount, setUpcomingPageCount] = useState(1);
  const [upcomingPageNumber, setUpcomingPageNumber] = useState(1);
  const [pastPageCount, setPastPageCount] = useState(1);
  const [pastPageNumber, setPastPageNumber] = useState(1);
  const [itemsPerPage] = useState(20);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null
  );
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<UpcomingBuybackItem[]>([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  // Fetch buyback items when the component mounts
  useEffect(() => {
    const pastBuybackItems = async () => {
      try {
        const response: BuybackResponse = await pastBuybackData("1W",itemsPerPage,pastPageNumber);
        const formattedData = response.data.map((item) => ({
          id: item.id,
          orderId: `${item.order_id}`,
          productName: item.product_name,
          customerFirstname: item.customer_firstname,
          customerLastname: item.customer_lastname,
          phoneNumber: item.customer_phone,
          emailId: item.customer_email,
          dateTime: new Date(item.buyback_date),
          buybackStatus: item.buyback_status
        }));

        setData(formattedData); // Store the full list for future reference
        setBuybackList(formattedData);
        setPastPageCount(Math.ceil(response.pagination.totalItemsCount/itemsPerPage));
      } catch (error) {
        console.error("Failed to fetch buyback items:", error);
      }
    };
    
    pastBuybackItems();
  }, [pastPageNumber, itemsPerPage]);

  useEffect(() => {
    const upcomingBuybackItems = async () => {
      try {
        const response: BuybackResponse = await upcomingBuybackData("1W",itemsPerPage,upcomingPageNumber);
        const formattedData = response.data.map((item) => ({
          id: item.id,
          orderId: `${item.order_id}`,
          productName: item.product_name,
          customerFirstname: item.customer_firstname,
          customerLastname: item.customer_lastname,
          phoneNumber: item.customer_phone,
          emailId: item.customer_email,
          dateTime: new Date(item.buyback_date),
          buybackStatus: item.buyback_status
        }));

        setData(formattedData);
        setUpcomingItems(formattedData);
        setUpcomingPageCount(Math.ceil(response.pagination.totalItemsCount/itemsPerPage));
      } catch (error) {
        console.error("Failed to fetch buyback items:", error);
      }
    };
  
    upcomingBuybackItems();
  }, [upcomingPageNumber, itemsPerPage]);

  const handleExtendClick = (item: UpcomingBuybackItem) => {
    const index = data.findIndex((dataItem) => dataItem.id === item.id);
    setSelectedItemIndex(index);
    setSelectedDate(item.dateTime);
    setIsPickerOpen(true);
  };

  const handleShareUrl = (item: UpcomingBuybackItem) => {
    const url = `/market/buyback/form/${item.id}`;
    window.open(url, "_blank");
  };

  // Save the selected date and update the backend
  const handleSaveDateTime = async () => {
    if (selectedItemIndex !== null && selectedDate) {
      // Validate selectedItemIndex
      if (selectedItemIndex < 0 || selectedItemIndex >= data.length) {
        console.error("Invalid selectedItemIndex:", selectedItemIndex);
        setSelectedItemIndex(null); // Reset the index
        setIsPickerOpen(false); // Close the picker
        return;
      }
  
      const buybackItem = data[selectedItemIndex];
  
      if (!buybackItem) {
        console.error("Buyback item is undefined:", selectedItemIndex);
        setSelectedItemIndex(null); // Reset the index
        setIsPickerOpen(false); // Close the picker
        return;
      }
  
      // Get the current time
      const currentTime = new Date();
  
      // Create a new date object that combines the selected date and current time
      const extendedDate = new Date(selectedDate);
      extendedDate.setHours(currentTime.getHours());
      extendedDate.setMinutes(currentTime.getMinutes());
      extendedDate.setSeconds(currentTime.getSeconds());
  
      try {
        const updatedItem = {
          ...buybackItem,
          buybackDate: extendedDate
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
        };
  
        // Update the item in the backend
        await updateBuybackItem(buybackItem.id, updatedItem);
  
        // Update the data state without overwriting the whole list
        setData((prevData) =>
          prevData.map((item, index) =>
            index === selectedItemIndex
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        // Directly update upcomingItems and buybackList
        setUpcomingItems((prevItems) =>
          prevItems.map((item) =>
            item.id === buybackItem.id
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        setBuybackList((prevItems) =>
          prevItems.map((item) =>
            item.id === buybackItem.id
              ? { ...item, dateTime: extendedDate }
              : item
          )
        );
  
        setIsPickerOpen(false);
        setSelectedItemIndex(null);
      } catch (error) {
        console.error("Failed to update buyback item:", error);
      }
    }
  };
  
  

  const handleDelete = async (id: number) => {
    if (window.confirm("Are you sure you want to delete this buyback item?")) {
      try {
        await deleteBuybackItem(id);
  
        // Remove the item from all relevant lists
        setData((prevData) => prevData.filter((item) => item.id !== id));
        setUpcomingItems((prevItems) => prevItems.filter((item) => item.id !== id));
        setBuybackList((prevItems) => prevItems.filter((item) => item.id !== id));
        
      } catch (error) {
        console.error("Failed to delete buyback item:", error);
      }
    }
  };

  const handleUpcomingPageClick = (event: { selected: number }) => {
    setUpcomingPageNumber(event.selected+1);
  };

  const handlePastPageClick = (event: { selected: number }) => {
    setPastPageNumber(event.selected+1);
  };

  const handleSearch = async(query: string) => {
    setSearchQuery(query);
    
    if (query.trim() === "") {
      setShowSearchResults(false);
      return;
    }
    
    try {
      const searchResults = await searchBuybackByParam(query);
      const formattedResults = searchResults.data.map((item: any) => ({
        id: item.id,
        orderId: `${item.order_id}`,
        productName: item.product_name,
        customerFirstname: item.customer_firstname,
        customerLastname: item.customer_lastname,
        phoneNumber: item.customer_phone,
        emailId: item.customer_email,
        dateTime: new Date(item.buyback_date),
        buybackStatus: item.buyback_status
      }));
  
      setSearchResults(formattedResults);
      setShowSearchResults(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]);
      setShowSearchResults(true);
    }
  };

  return (
    <div>
      {/* Search Bar */}
      <div className="flex justify-end">
        <div className="relative w-64 justify-items-end mb-4">
          <input
            type="text"
            placeholder="Search by order id, name..."
            className="w-full bg-white px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#f78a8a]"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {searchQuery && (
            <button
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              onClick={() => {
                setSearchQuery("");
                setShowSearchResults(false);
              }}
            >
              ×
            </button>
          )}
        </div>
      </div>

      {/* Search Results Section - Only visible when there's a search query */}
      {searchQuery && (
        <div className="rounded-lg justify-center bg-white p-6 shadow-lg mb-6">
          <div className="flex justify-between items-center mb-6">
           <div className="flex justify-center">
           <h2 className="text-2xl font-semibold text-[#f78a8a]">
              Search Buyback Items
            </h2>
           </div>
          </div>
          {/* Search Results Table */}
          {showSearchResults && searchResults.length > 0 && (
            <table className="w-full">
              <thead>
                <tr className="bg-[#f78a8a] text-white">
                  <th className="py-2 px-4 text-left">ORDER ID</th>
                  <th className="py-2 px-4 text-left">PRODUCT NAME</th>
                  <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
                  <th className="py-2 px-4 text-left">PHONE NUMBER</th>
                  <th className="py-2 px-4 text-left">EMAIL ID</th>
                  <th className="py-2 px-4 text-left">DATE</th>
                  <th className="py-2 px-4 text-left">STATUS</th>
                  <th className="py-2 px-4 text-left">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="py-2 px-4">{item.orderId}</td>
                    <td className="py-2 px-4">{item.productName}</td>
                    <td className="py-2 px-4">{`${item.customerFirstname} ${item.customerLastname}`}</td>
                    <td className="py-2 px-4">{item.phoneNumber}</td>
                    <td className="py-2 px-4">{item.emailId}</td>
                    <td className="py-2 px-4">
                      {item.dateTime.toLocaleDateString()}{" "}
                    </td>
                    <td className="py-2 px-4">{item.buybackStatus}</td>
                    <td className="py-2 px-4">
                      <div className="flex gap-2">
                        <button
                          className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                            item.buybackStatus !== 'PENDING'
                              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 hover:scale-105 hover:shadow-md'
                          }`}
                          onClick={() => handleExtendClick(item)}
                          disabled={item.buybackStatus !== 'PENDING'}
                        >
                          EXTEND
                        </button>
                        <button
                          className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                            item.buybackStatus !== 'PENDING'
                              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                              : 'bg-red-500 hover:scale-105 hover:shadow-md'
                          }`}
                          onClick={() => handleDelete(item.id)}
                          disabled={item.buybackStatus !== 'PENDING'}
                        >
                          REMOVE
                        </button>
                        <button
                          className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                            item.buybackStatus !== 'PENDING'
                              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                              : 'bg-green-500 hover:scale-105 hover:shadow-md'
                          }`}
                          onClick={() => handleShareUrl(item)}
                          disabled={item.buybackStatus !== 'PENDING'}
                        >
                          SHARE URL
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          
          {showSearchResults && searchResults.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No results found for "{searchQuery}"
            </div>
          )}
        </div>
      )}

      {/* Your existing Upcoming Buyback and Buyback List sections remain unchanged */}
      <div className="rounded-lg bg-white p-6 shadow-lg">
        <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
          Upcoming Buyback
        </h2>
        <table className="w-full">
          <thead>
            <tr className="bg-[#f78a8a] text-white">
              <th className="py-2 px-4 text-left">ORDER ID</th>
              <th className="py-2 px-4 text-left">PRODUCT NAME</th>
              <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
              <th className="py-2 px-4 text-left">PHONE NUMBER</th>
              <th className="py-2 px-4 text-left">EMAIL ID</th>
              <th className="py-2 px-4 text-left">DATE</th>
              <th className="py-2 px-4 text-left">STATUS</th>
              <th className="py-2 px-4 text-left">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {upcomingItems.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="py-2 px-4">{item.orderId}</td>
                <td className="py-2 px-4">{item.productName}</td>
                <td className="py-2 px-4">{`${item.customerFirstname} ${item.customerLastname}`}</td>
                <td className="py-2 px-4">{item.phoneNumber}</td>
                <td className="py-2 px-4">{item.emailId}</td>
                <td className="py-2 px-4">
                  {item.dateTime.toLocaleDateString()}{" "}               
                </td>
                <td className="py-2 px-4">{item.buybackStatus}</td>
                <td className="py-2 px-4">
                  <div className="flex gap-2">
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-blue-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleExtendClick(item)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      EXTEND
                    </button>
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-red-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleDelete(item.id)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      REMOVE
                    </button>
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-green-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleShareUrl(item)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      SHARE URL
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          className="flex items-center justify-center gap-2 mt-4"
          pageLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          previousLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          nextLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          activeLinkClassName="!bg-primary !text-white hover:!bg-primary"
          disabledLinkClassName="opacity-50 cursor-not-allowed"
          breakLinkClassName="px-3 py-2"
          pageCount={upcomingPageCount}
          onPageChange={handleUpcomingPageClick}
          renderOnZeroPageCount={null}
        />

        {/* Buyback List Section */}
        <h2 className="mt-10 mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
          Buyback List
        </h2>
        <table className="w-full">
          <thead>
            <tr className="bg-[#f78a8a] text-white">
              <th className="py-2 px-4 text-left">ORDER ID</th>
              <th className="py-2 px-4 text-left">PRODUCT NAME</th>
              <th className="py-2 px-4 text-left">CUSTOMER NAME</th>
              <th className="py-2 px-4 text-left">PHONE NUMBER</th>
              <th className="py-2 px-4 text-left">EMAIL ID</th>
              <th className="py-2 px-4 text-left">DATE</th>
              <th className="py-2 px-4 text-left">STATUS</th>
              <th className="py-2 px-4 text-left">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {buybackList.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="py-2 px-4">{item.orderId}</td>
                <td className="py-2 px-4">{item.productName}</td>
                <td className="py-2 px-4">{`${item.customerFirstname} ${item.customerLastname}`}</td>
                <td className="py-2 px-4">{item.phoneNumber}</td>
                <td className="py-2 px-4">{item.emailId}</td>
                <td className="py-2 px-4">
                  {item.dateTime.toLocaleDateString()}{" "}               
                </td>
                <td className="py-2 px-4">{item.buybackStatus}</td>
                <td className="py-2 px-4">
                  <div className="flex gap-2">
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-blue-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleExtendClick(item)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      EXTEND
                    </button>
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-red-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleDelete(item.id)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      REMOVE
                    </button>
                    <button
                      className={`rounded-full px-4 py-2 text-xs font-semibold text-white transition-all ${
                        item.buybackStatus !== 'PENDING'
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-green-500 hover:scale-105 hover:shadow-md'
                      }`}
                      onClick={() => handleShareUrl(item)}
                      disabled={item.buybackStatus !== 'PENDING'}
                    >
                      SHARE URL
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          className="flex items-center justify-center gap-2 mt-4"
          pageLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          previousLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          nextLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          activeLinkClassName="!bg-primary !text-white hover:!bg-primary"
          disabledLinkClassName="opacity-50 cursor-not-allowed"
          breakLinkClassName="px-3 py-2"
          pageCount={pastPageCount}
          onPageChange={handlePastPageClick}
          renderOnZeroPageCount={null}
        />

        {/* Date Picker Modal */}
        {isPickerOpen && selectedDate && (
         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
         <div className="rounded-lg bg-white p-4 shadow-lg w-64">
           <h3 className="mb-4 text-lg font-semibold">Select Date</h3>
           <DatePicker
             selected={selectedDate}
             onChange={(date) => setSelectedDate(date)}
             dateFormat="dd/MM/yyyy"
             className="mb-4 w-full rounded border border-gray-300 p-2 bg-white text-gray-800"
           />
            <div className="mt-4 flex gap-2">
              <button
                onClick={handleSaveDateTime}
                className="bg-green-500 px-4 py-2 text-white rounded-full"
              >
                Save
              </button>
              <button
                onClick={() => setIsPickerOpen(false)}
                className="bg-gray-500 px-4 py-2 text-white rounded-full"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}
