import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { getBuybackReviews, updateBuybackReview, searchBuybackReviewsByParam} from "../api/AdminApi";
import { BuybackReviewTable } from "../types/types";
import { getBuybackReviewMedias } from "../../market/api/MarketApiClient";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface ReviewBuybackItem {
  productName: string;
  id: number;
  orderId: string;
  reviewId: number;
  csDecision: "PENDING" | "REJECTED" | "ACCEPTED" | "CONDITIONALLY_ACCEPTED";
  inspectionStatus: string;
  buybackPrice: string;
  customerAddress: string;
  images?: string[];
  customerComments: string;
  storeDrop: boolean;
  csReviewDate: string;
  alternatePhoneNo: string;
  customerPhone: string;
  paymentMethod: string;
  adminReviewComments: string;
}

export function ReviewBuyback() {
  const [data, setData] = useState<ReviewBuybackItem[]>([]);
  const [comments, setComments] = useState<{ [key: number]: string }>({});
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [reviewsPageCount, setReviewsPageCount] = useState(1);
  const [reviewsPageNumber, setReviewsPageNumber] = useState(1);
  const [itemsPerPage] = useState(20);
  const [commentWarnings, setCommentWarnings] = useState<{
    [key: number]: string;
  }>({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<ReviewBuybackItem[]>([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const additionalFilters = {
          inspection_status: ["PENDING", "REJECTED"]
        };
      
        const reviewsResponse = await getBuybackReviews(itemsPerPage, reviewsPageNumber, additionalFilters);
        
        const reviews: ReviewBuybackItem[] = await Promise.all(
          reviewsResponse.data.map(async (item: BuybackReviewTable) => {
            // const itemDetails = itemsMap.get(item.item_id);
            let images: string[] = [];
            try {
              const imageResponse = await getBuybackReviewMedias(
                item.item_id,
                item.id
              );
              if (imageResponse.data) {
                images = imageResponse.data.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (media: { media_url: any }) => media.media_url
                );
              }
            } catch (error) {
              console.error("Error fetching images:", error);
            }

            return {
              productName: item.product_name,
              buybackPrice: item.buyback_price,
              customerAddress: item.customer_address,
              csDecision: item.cs_review_status || "PENDING",
              inspectionStatus: item.inspection_status,
              id: item.item_id,
              orderId: `${item.order_id}`,
              reviewId: item.id,
              images: images,
              customerComments: item.customer_comments || "",
              adminReviewComments: item.review_comments,
              storeDrop: Boolean(item.store_drop),
              csReviewDate: new Date(item.cs_review_date).toLocaleDateString(),
              alternatePhoneNo: item?.alternate_phone_no || "",
              customerPhone: item?.customer_phone || "",
              paymentMethod: item?.payment_method || ""
            };
          })
        );
        setReviewsPageCount(Math.ceil(reviewsResponse.pagination.totalItemsCount/itemsPerPage));
        setData(reviews);
      } catch (error) {
        console.error("Error fetching buyback reviews:", error);
      }
    };

    fetchReviews();
  }, [reviewsPageNumber, itemsPerPage]);

  const handleReviewsPageClick = (event: { selected: number }) => {
    setReviewsPageNumber(event.selected+1);
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleUpdateReview = async (
    productId: number,
    reviewId: number,
    status: "ACCEPTED" | "CONDITIONALLY_ACCEPTED",
    productName: string
  ) => {
    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: status,
      });

      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: status } : item
        )
      );

      setSearchResults((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: status } : item
        )
      );

      alert(`Product ${productName} has been marked as ${status}`);
    } catch {
      alert("Failed to update review.");
    }
  };

  const handleReject = async (productId: number, reviewId: number) => {
    const comment = comments[productId];
    if (!comment?.trim()) {
      setCommentWarnings((prev) => ({
        ...prev,
        [productId]: "Please add comments before rejecting the product",
      }));
      return;
    }

    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: "REJECTED",
        reviewComments: comments[productId],
      });

      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: "REJECTED" } : item
        )
      );
      setSearchResults((prevData) =>
        prevData.map((item) =>
          item.id === productId ? { ...item, csDecision: "REJECTED" } : item
        )
      );
      // Clear warning after successful action
      setCommentWarnings((prev) => ({ ...prev, [productId]: "" }));
    } catch {
      alert("Failed to reject review.");
    }
  };

  const handleConditionalAccept = async (
    productId: number,
    reviewId: number
  ) => {
    const comment = comments[productId];
    if (!comment?.trim()) {
      setCommentWarnings((prev) => ({
        ...prev,
        [productId]:
          "Please add comments before conditionally accepting the product",
      }));
      return;
    }

    try {
      await updateBuybackReview(productId, reviewId, {
        csReviewStatus: "CONDITIONALLY_ACCEPTED",
        reviewComments: comments[productId],
      });
      setData((prevData) =>
        prevData.map((item) =>
          item.id === productId
            ? { ...item, csDecision: "CONDITIONALLY_ACCEPTED" }
            : item
        )
      );
      setSearchResults((prevData) =>
        prevData.map((item) =>
          item.id === productId
            ? { ...item, csDecision: "CONDITIONALLY_ACCEPTED" }
            : item
        )
      );
      // Clear warning after successful action
      setCommentWarnings((prev) => ({ ...prev, [productId]: "" }));
    } catch {
      alert("Failed to update review.");
    }
  };
  
  const handleSearch = async (query: string) => {
    setSearchQuery(query);
  
    if (query.trim() === "") {
      setShowSearchResults(false);
      return;
    }
  
    try {
      const searchResults = await searchBuybackReviewsByParam(query);
  
      const formattedResults = await Promise.all(
        searchResults.data.map(async (item: any) => {
          let images: string[] = [];
          try {
            const imageResponse = await getBuybackReviewMedias(
              item.item_id,
              item.id
            );
            if (imageResponse.data) {
              images = imageResponse.data.map(
                (media: { media_url: string }) => media.media_url
              );
            }
          } catch (error) {
            console.error("Error fetching images for item:", item.item_id, error);
          }
  
          return {
            productName: item.product_name,
            buybackPrice: item.buyback_price,
            customerAddress: item.customer_address,
            csDecision: item.cs_review_status || "PENDING",
            inspectionStatus: item.inspection_status,
            id: item.item_id,
            orderId: `${item.order_id}`,
            reviewId: item.id,
            images: images, // Include fetched images
            customerComments: item.customer_comments || "",
            adminReviewComments: item.review_comments,
            storeDrop: Boolean(item.store_drop),
            csReviewDate: new Date(item.cs_review_date).toLocaleDateString(),
            alternatePhoneNo: item?.alternate_phone_no || "",
            customerPhone: item?.customer_phone || "",
            paymentMethod: item?.payment_method || "",
          };
        })
      );
  
      setSearchResults(formattedResults);
      setShowSearchResults(true);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]);
      setShowSearchResults(true);
    }
  };  

  return (
    <div className="rounded-lg bg-white p-6 shadow-lg">
      {/* Search Bar */}
      <div className="flex justify-end">
        <div className="relative w-64 justify-items-end mb-4">
          <input
            type="text"
            placeholder="Search by order id, name..."
            className="w-full bg-white px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#f78a8a]"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          {searchQuery && (
            <button
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
              onClick={() => {
                setSearchQuery("");
                setShowSearchResults(false);
              }}
            >
              ×
            </button>
          )}
        </div>
      </div>
      
      {/* Search Results Section - Only visible when there's a search query */}
      {searchQuery && (
        <div className="rounded-lg justify-center bg-white p-6 shadow-lg mb-6">
          <div className="flex justify-between items-center mb-6">
           <div className="flex justify-center">
           <h2 className="text-2xl font-semibold text-[#f78a8a]">
              Search Reviews Items
            </h2>
           </div>
          </div>
          {/* Search Results Table */}
          {showSearchResults && searchResults.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {searchResults.map((item) => (
              <div
                key={item.id}
                className="bg-white rounded-lg shadow-md p-6 relative">
                <div className="absolute top-2 left-2 text-xl px-2 py-1 rounded-full bg-gray-200">
                  {item.orderId}
                </div>
                <div
                  className={`absolute top-2 right-2 text-xs px-2 py-1 rounded ${item.csDecision === "ACCEPTED"
                      ? "bg-green-500 text-white"
                      : item.csDecision === "CONDITIONALLY_ACCEPTED"
                        ? "bg-blue-500 text-white"
                        : item.csDecision === "REJECTED"
                          ? "bg-red-500 text-white"
                          : "bg-gray-200 text-gray-700"
                    }`}
                >
                  {item.csDecision}
                </div>
    
                <div className="flex gap-2 mb-4 mt-7">
                  {item.images && item.images.length > 0 ? (
                    item.images.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image}
                        alt={`${item.productName} image ${imgIndex + 1}`}
                        className="w-20 h-20 object-cover rounded-md cursor-pointer"
                        onClick={() => handleImageClick(image)}
                      />
                    ))
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
    
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        PRODUCT NAME
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.productName}
                      </p>
                    </div>
    
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        BUYBACK PRICE
                      </h3>
                      <p className="text-base font-bold text-md">
                        AED {item.buybackPrice}
                      </p>
                    </div>
    
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        CUSTOMER ADDRESS
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.customerAddress}
                      </p>
                    </div>
    
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        CUSTOMER COMMENTS
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.customerComments}
                      </p>
                    </div>
                  </div>
    
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        STORE DROP
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.storeDrop ? "Yes" : "No"}
                      </p>
                    </div>
    
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        DSIRED PICKUP DATE
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.csReviewDate}
                      </p>
                    </div>
    
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        PHONE NUMBER
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.customerPhone}, {item.alternatePhoneNo}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-x-sm text-gray-500">
                        PAYMENT METHOD
                      </h3>
                      <p className="text-base font-bold text-md">
                        {item.paymentMethod}
                      </p>
                    </div>
                  </div>
                </div>
    
                <div className="mt-4">
                <textarea
                  placeholder="Add comments here... (Required for Conditional Accept and Reject)"
                  className={`w-full p-2 border rounded-lg bg-white text-left ${
                    commentWarnings[item.id] ? "border-red-500" : "border-gray-300"
                  }`}
                  value={comments[item.id] ?? item.adminReviewComments ?? ""}
                  onChange={(e) => {
                    const updatedValue = e.target.value;
                    setComments((prev) => ({
                      ...prev,
                      [item.id]: updatedValue,
                    }));
                  }}
                />
                  {commentWarnings[item.id] && (
                    <p className="text-red-500 text-sm mt-1">
                      {commentWarnings[item.id]}
                    </p>
                  )}
                </div>
    
                <div className="flex gap-2 mt-4">
                  <button
                    className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "ACCEPTED" || item.inspectionStatus === "ACCEPTED"
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-green-500 text-white hover:bg-green-600"
                      }`}
                    onClick={() =>
                      handleUpdateReview(
                        item.id,
                        item.reviewId,
                        "ACCEPTED",
                        item.productName
                      )
                    }
                    disabled={item.csDecision === "ACCEPTED" || item.inspectionStatus === "ACCEPTED"}
                  >
                    ACCEPT
                  </button>
                  <button
                    className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "CONDITIONALLY_ACCEPTED" || item.inspectionStatus === "ACCEPTED"
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-blue-500 text-white hover:bg-blue-600"
                      }`}
                    onClick={() => handleConditionalAccept(item.id, item.reviewId)}
                    disabled={item.csDecision === "CONDITIONALLY_ACCEPTED" || item.inspectionStatus === "ACCEPTED"}
                  >
                    CONDITIONAL ACCEPT
                  </button>
                  <button
                    className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "REJECTED" || item.inspectionStatus === "ACCEPTED"
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "bg-red-500 text-white hover:bg-red-600"
                      }`}
                    onClick={() => handleReject(item.id, item.reviewId)}
                    disabled={item.csDecision === "REJECTED" || item.inspectionStatus === "ACCEPTED"}
                  >
                    REJECT
                  </button>
                </div>
              </div>
            ))}
          </div>
          )}
          
          {showSearchResults && searchResults.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No results found for "{searchQuery}"
            </div>
          )}
        </div>
      )}

      <h2 className="mb-6 text-center text-2xl font-semibold text-[#f78a8a]">
        Review Buyback
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {data.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg shadow-md p-6 relative">
            <div className="absolute top-2 left-2 text-xl px-2 py-1 rounded-full bg-gray-200">
              {item.orderId}
            </div>
            <div
              className={`absolute top-2 right-2 text-xs px-2 py-1 rounded ${item.csDecision === "ACCEPTED"
                  ? "bg-green-500 text-white"
                  : item.csDecision === "CONDITIONALLY_ACCEPTED"
                    ? "bg-blue-500 text-white"
                    : item.csDecision === "REJECTED"
                      ? "bg-red-500 text-white"
                      : "bg-gray-200 text-gray-700"
                }`}
            >
              {item.csDecision}
            </div>

            <div className="flex gap-2 mb-4 mt-7">
              {item.images && item.images.length > 0 ? (
                item.images.map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={image}
                    alt={`${item.productName} image ${imgIndex + 1}`}
                    className="w-20 h-20 object-cover rounded-md cursor-pointer"
                    onClick={() => handleImageClick(image)}
                  />
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-6">
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PRODUCT NAME
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.productName}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    BUYBACK PRICE
                  </h3>
                  <p className="text-base font-bold text-md">
                    AED {item.buybackPrice}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    CUSTOMER ADDRESS
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerAddress}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    CUSTOMER COMMENTS
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerComments}
                  </p>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    STORE DROP
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.storeDrop ? "Yes" : "No"}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    DSIRED PICKUP DATE
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.csReviewDate}
                  </p>
                </div>

                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PHONE NUMBER
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.customerPhone}, {item.alternatePhoneNo}
                  </p>
                </div>
                <div>
                  <h3 className="text-x-sm text-gray-500">
                    PAYMENT METHOD
                  </h3>
                  <p className="text-base font-bold text-md">
                    {item.paymentMethod}
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4">
            <textarea
              placeholder="Add comments here... (Required for Conditional Accept and Reject)"
              className={`w-full p-2 border rounded-lg bg-white text-left ${
                commentWarnings[item.id] ? "border-red-500" : "border-gray-300"
              }`}
              value={comments[item.id] ?? item.adminReviewComments ?? ""}
              onChange={(e) => {
                const updatedValue = e.target.value;
                setComments((prev) => ({
                  ...prev,
                  [item.id]: updatedValue,
                }));
              }}
            />
              {commentWarnings[item.id] && (
                <p className="text-red-500 text-sm mt-1">
                  {commentWarnings[item.id]}
                </p>
              )}
            </div>

            <div className="flex gap-2 mt-4">
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "ACCEPTED" || item.inspectionStatus === "ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-green-500 text-white hover:bg-green-600"
                  }`}
                onClick={() =>
                  handleUpdateReview(
                    item.id,
                    item.reviewId,
                    "ACCEPTED",
                    item.productName
                  )
                }
                disabled={item.csDecision === "ACCEPTED" || item.inspectionStatus === "ACCEPTED"}
              >
                ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "CONDITIONALLY_ACCEPTED" || item.inspectionStatus === "ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                  }`}
                onClick={() => handleConditionalAccept(item.id, item.reviewId)}
                disabled={item.csDecision === "CONDITIONALLY_ACCEPTED" || item.inspectionStatus === "ACCEPTED"}
              >
                CONDITIONAL ACCEPT
              </button>
              <button
                className={`flex-1 px-4 py-2 rounded-full text-center ${item.csDecision === "REJECTED" || item.inspectionStatus === "ACCEPTED"
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-red-500 text-white hover:bg-red-600"
                  }`}
                onClick={() => handleReject(item.id, item.reviewId)}
                disabled={item.csDecision === "REJECTED" || item.inspectionStatus === "ACCEPTED"}
              >
                REJECT
              </button>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          previousLabel="Previous"
          className="flex items-center justify-center gap-2 mt-4"
          pageLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          previousLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          nextLinkClassName="px-3 py-2 rounded-md border border-gray-300 hover:bg-gray-100"
          activeLinkClassName="!bg-primary !text-white hover:!bg-primary"
          disabledLinkClassName="opacity-50 cursor-not-allowed"
          breakLinkClassName="px-3 py-2"
          pageCount={reviewsPageCount}
          onPageChange={handleReviewsPageClick}
          renderOnZeroPageCount={null}
        />
      {selectedImage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded-lg w-[36vw] h-[74vh] mx-auto relative">
            <button
              onClick={handleCloseImage}
              className="absolute -top-10 right-0 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 z-50"
            >
              X
            </button>
            <TransformWrapper initialScale={1} centerOnInit={true}>
              {({ zoomIn, zoomOut, resetTransform, centerView }) => (
                <>
                  <div className="flex gap-2 mb-4 justify-center">
                    <button
                      onClick={() => zoomIn()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Zoom In
                    </button>
                    <button
                      onClick={() => zoomOut()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Zoom Out
                    </button>
                    <button
                      onClick={() => centerView()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Center
                    </button>
                    <button
                      onClick={() => resetTransform()}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                    >
                      Reset
                    </button>
                  </div>
                  <TransformComponent>
                    <img
                      src={selectedImage}
                      alt="Zoomable"
                      className="object-contain rounded-lg w-[78vw] h-[65vh] mx-auto relative"
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewBuyback;
