import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBuybackItems, getBuybackReviews, getBuybackReviewMedias } from '../api/MarketApiClient'
import { BuybackItem, BuybackReviewTable, BuybackReviewMedia } from '../../admin/types/types';

interface CombinedDetails {
  buybackDetails: BuybackItem | null;
  reviewDetails: BuybackReviewTable | null;
  mediaDetails: BuybackReviewMedia[];
}

const BuybackFormAfterSubmit: React.FC = () => {
  const [details, setDetails] = useState<CombinedDetails>({
    buybackDetails: null,
    reviewDetails: null,
    mediaDetails: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { itemId } = useParams<{ itemId: string }>();

  useEffect(() => {
    const fetchAllDetails = async () => {
      try {
        // Fetch buyback item details
        const buybackResponse = await getBuybackItems();
        const buybackItem = buybackResponse.data.find(
          item => item.id === parseInt(itemId || '0')
        );

        if (!buybackItem) {
          throw new Error('Buyback item not found');
        }

        // Fetch review details
        const reviewResponse = await getBuybackReviews(buybackItem.id);
        const reviewItem = reviewResponse.data.find(
          review => review.item_id === parseInt(itemId || '0')
        );

        // Fetch media details if review exists
        let mediaDetails: BuybackReviewMedia[] = [];
        if (reviewItem) {
          const mediaResponse = await getBuybackReviewMedias(parseInt(itemId || '0'), reviewItem.id);
          mediaDetails = mediaResponse.data;
        }

        setDetails({
          buybackDetails: buybackItem,
          reviewDetails: reviewItem || null,
          mediaDetails
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch details');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    if (itemId) {
      fetchAllDetails();
    }
  }, [itemId]);

  if (loading) {
    return <div className="text-center p-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-8">{error}</div>;
  }

  const { buybackDetails, reviewDetails, mediaDetails } = details;

  if (!buybackDetails || !reviewDetails) {
    return <div className="text-center p-8">No details found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-2xl font-bold text-center text-red-400 mb-8">
          Buyback Request Submitted Successfully
        </h1>

        {/* Product Information */}
        <div className="space-y-6">
          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Product Information</h2>
            <div className="grid gap-4">
              <div>
                <label className="font-medium text-gray-700">Order Id:</label>
                <p className="mt-1">{buybackDetails.order_id}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Product Name:</label>
                <p className="mt-1">{buybackDetails.product_name}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Buyback Price:</label>
                <p className="mt-1">{buybackDetails.buyback_price}</p>
              </div>
            </div>
          </div>

          {/* Review Details */}
          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Review Details</h2>
            <div className="grid gap-4">
              <div>
                <label className="font-medium text-gray-700">Comments:</label>
                <p className="mt-1">{reviewDetails.customer_comments}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Review Date:</label>
                <p className="mt-1">{new Date(reviewDetails.cs_review_date).toLocaleDateString()}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Pickup Address:</label>
                <p className="mt-1">{reviewDetails.customer_address}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Store Drop:</label>
                <p className="mt-1">{reviewDetails.store_drop ? 'Yes' : 'No'}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Buyback Status:</label>
                <p className="mt-1">
                  <span className="px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                    {reviewDetails.buyback_status}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Media Section */}
          {mediaDetails.length > 0 && (
            <div className="border-b pb-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">Uploaded Media</h2>
              <div className="grid grid-cols-3 gap-4">
                {mediaDetails.map((media, index) => (
                  <div key={media.id} className="relative">
                    {media.media_type === 'IMAGE' && (
                      <img
                        src={media.media_url}
                        alt={`Upload ${index + 1}`}
                        className="w-full h-32 object-cover rounded-lg"
                      />
                    )}
                    <p className="mt-1 text-sm text-gray-500">Image {index + 1}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Contact Information */}
          <div className="border-b pb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Contact Information</h2>
            <div className="grid gap-4">
              <div>
                <label className="font-medium text-gray-700">Name:</label>
                <p className="mt-1">{`${buybackDetails.customer_firstname} ${buybackDetails.customer_lastname}`}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Email:</label>
                <p className="mt-1">{buybackDetails.customer_email}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Phone:</label>
                <p className="mt-1">{buybackDetails.customer_phone}</p>
              </div>
              <div>
                <label className="font-medium text-gray-700">Alternate Phone:</label>
                <p className="mt-1">{buybackDetails.alternate_phone_no || 'Not provided'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuybackFormAfterSubmit;