import axios from 'axios';
import { Category, Product, StoreView, CartResponse, AddToCartRequest, ProductInCart, FilterOption, CatalogDataResponse, ProductApiResponse} from '../types/types';
import { BuybackItem, BuybackResponse, BuybackReview, BuybackReviewResponse } from '../../admin/types/types';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchStores = async (): Promise<StoreView[]> => {
  const response = await apiClient.get('/market/store/storeviews');
  return response.data;
};

export const fetchCategories = async (storeCode: string): Promise<Category> => {
  const response = await apiClient.get(`/market/store/${storeCode}/categories`);
  return response.data;
};

export const fetchProducts = async (
  storeCode: string,
  categoryId: number,
  currentPage: number,
  filters: Record<string, string | string[]> // Flexible key-value pairs for filters
): Promise<ProductApiResponse> => {
  try {
    // Build query parameters
    const params = new URLSearchParams();
    params.append('currentPage', currentPage.toString()); 
    // Iterate over filters object
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        params.append(key, value.join(',')); // Join array values with commas
      } else if (value) {
        params.append(key, value); // Append single values
      }
    }

    // Fetch products
    const response = await apiClient.get(`/market/store/${storeCode}/categories/${categoryId}/products?${params.toString()}`);
    const baseUrl = `${API_BASE_URL}/market/media/catalog/product`;

    if (!response.data[0]?.products) {
      return {
        products: [],
        pagination: {
          current_page: 1,
          page_size: 0,
          total_pages: 0,
          total_items: 0,
        },
      };
    }

    const products = response.data[0].products.map((product: Product) => {
      const mediaUrls = product.media_urls || {};
      return {
        id: product.id || '',
        sku: product.sku || '',
        name: product.name || '',
        price: product.price || '0',
        special_price: product.special_price !== null ? product.special_price : null,
        discount_amount: product.discount_amount || 0,
        price_with_tax: product.price_with_tax || 0,
        special_price_with_tax: product.special_price_with_tax !== null ? product.special_price_with_tax : null,
        url_key: product.url_key || '',
        product_url_key: product.url_key || '',
        description: product.description || '',
        reviews_count: product.reviews_count || 0,
        reviews_detail: product.reviews_detail || [],
        stock_status: product.stock_status || false,
        stock_count: product.stock_count || 0,
        media_urls: {
          thumbnail: mediaUrls.thumbnail ? `${baseUrl}?path=${mediaUrls.thumbnail}` : '',
          small: mediaUrls.small ? `${baseUrl}?path=${mediaUrls.small}` : '',
          large: mediaUrls.large ? `${baseUrl}?path=${mediaUrls.large}` : '',
          file: mediaUrls.file ? mediaUrls.file.map((url: string) => `${baseUrl}${url}`) : [],
        },
        brand: product.brand || '',
        in_stock: product.in_stock || false,
        day_to_deliver: product.day_to_deliver || '',
        buyback: product.buyback || 'No',
        average_rating: product.average_rating || 0,
        know_your_size: product.know_your_size || '',
        legal_disclamer: product.legal_disclamer || '',
        medical_detail: product.medical_detail || '',
      };
    });
    // Extract pagination info
    const pagination = {
      current_page: response.data[0]?.pagination?.current_page || 1,
      page_size: response.data[0]?.pagination?.page_size || products.length,
      total_pages: response.data[0]?.pagination?.total_pages || 0,
      total_items: response.data[0]?.pagination?.total_items || products.length,
    };

    return { products, pagination }; // Return both products and pagination
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};


export const fetchProductDetails = async (storeCode: string, urlKey: string) => {
  const response = await apiClient.get(`/market/store/${storeCode}/products/${urlKey}`);
  return response.data[0];
};

// Updated function to fetch the token
export const fetchToken = async (email: string): Promise<string | null> => {
  try {
    const response = await apiClient.post('/customers/login', {
      email: email, 
    });
    return response.data || null;  
  } catch (error) {
    console.error('Error fetching token:', error);
    return null;
  }
};

export const fetchCartItems = async (token: string): Promise<CartResponse | null> => {
  try {
    const response = await apiClient.get<CartResponse>('/market/store/default/carts/mine', {
      headers: {
        customertoken: token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching cart items:', error);
    return null;
  }
};

export const addProductToCart = async (sku: string, qty: number, customertoken: string) => {
  try {
    const requestData: AddToCartRequest = { sku, qty };
    
    const response = await apiClient.post<ProductInCart>(
      '/market/store/default/carts/mine/items',
      requestData,
      {
        headers: {
          'customertoken': customertoken,
        },
      }
    );

    if (!response.data) {
      throw new Error('No data returned from the server');
    }

    console.log("Product added to cart:", response.data);
    return response.data;
  } catch (error) {
    // New error handling block
    if (axios.isAxiosError(error)) {
      console.error("Error adding product to cart:", error.response?.data || error.message);
    } else {
      console.error("Unexpected error:", error);
    }
    throw error; 
  }
}

export const fetchFilterOptionsByCategoryId = async (
  storeCode: string,
  categoryId: string
): Promise<FilterOption[]> => {
  try {
    const response = await apiClient.get(`/market/store/${storeCode}/categories/${categoryId}/filter-options`);
    return response.data;
  } catch (error) {
    console.error('Error fetching filter options:', error);
    throw error;
  }
};

export const fetchCatalogData = async (): Promise<CatalogDataResponse> => {
  try {
    const response = await apiClient.get<CatalogDataResponse>('/market/catalog-data');
    return response.data;
  } catch (error) {
    console.error('Error fetching catalog data:', error);
    throw error;
  }
};

// Function to fetch buyback items
export const getBuybackItems = async (page: number = 1): Promise<BuybackResponse> => {
  try {
    const response = await apiClient.get<BuybackResponse>(`/market/buyback`, {
      params: {
        page,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback items:", error);
    throw error;
  }
};

export const getBuybackItem = async (id: number): Promise<BuybackItem> => {
  try {
    const response = await apiClient.get<BuybackItem>(`/market/buyback/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback items:", error);
    throw error;
  }
};

// Function to post a review for a buyback item
export const postBuybackReview = async (
  id: number,
  reviewData: Omit<
    BuybackReview,
    "csReviewStatus" | "inspectionStatus" | "inspectionNotes"
  >
) => {
  try {
    const defaultData = {
      csReviewStatus: "PENDING",
      inspectionStatus: "PENDING",
      inspectionNotes: "Needs further inspection",
    };

    const response = await apiClient.post(`/market/buyback/${id}/reviews`, {
      ...defaultData,
      ...reviewData, // Merge user-provided review data with default values
    });

    return response.data;
  } catch (error) {
    console.error("Error posting buyback review:", error);
    throw error;
  }
};

// Function to upload media for a buyback review
export const uploadBuybackReviewMedia = async (
  itemId: number,
  mediaFile: File,
  mediaType: 'IMAGE' | 'VIDEO' | 'DOCUMENT'
) => {
  try {
    const formData = new FormData();
    formData.append('mediaFile', mediaFile);
    formData.append('mediaType', mediaType);

    const response = await apiClient.post(
      `/market/buyback/${itemId}/medias`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};

export const getBuybackReviews = async (buybackId: number): Promise<BuybackReviewResponse> => {
  try {
    const response = await apiClient.get<BuybackReviewResponse>(
      `/market/buyback/${buybackId}/reviews`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback reviews:", error);
    throw error;
  }
};

export const getBuybackReviewMedias = async (itemId: number, reviewId: number) => {
  try {
    const response = await apiClient.get(`/market/buyback/${itemId}/reviews/${reviewId}/medias`);
    return response.data;
  } catch (error) {
    console.error("Error fetching buyback review medias:", error);
    throw error;
  }
};